<template>
  <div class="home">
    <a href="https://pbriet.github.io" target="_blank">
      <button class="button" id="cv-button">
        <i class="fas fa-external-link-alt"></i>&nbsp;
          {{ trans('SEE_CV') }}
      </button>
    </a>

    <select id="language-select" :value="lang" @change="changeLang">
      <option
        v-for="option in languageOptions"
        :key="option.value"
        :value="option.value">
        {{ option.text }}
      </option>
    </select>

    <div id="title-part" class="block">
      <div class="fr-content">
        <h1>Freelance Devops/Dev</h1>
        <div class="columns is-mobile is-centered is-multiline infos">
          <div class="column is-12-mobile">
            <i class="fas fa-user"></i> Pascal BRIET<br/>{{ trans('AGE') }}
          </div>
          <div class="column is-12-mobile">
            <i class="fas fa-map-marker"></i> Nantes, Angers,<br/>Ancenis
          </div>
          <div class="column is-12-mobile">
            <i class="fas fa-clock"></i> {{ trans('WORK_MODALITIES') }}
          </div>
        </div>
      </div>
    </div>

    <div class="fr-subtitle">
      <div class="fr-content">
        <h2>{{ trans('KNOWLEDGE') }}</h2>
      </div>
    </div>

    <div id="missions">
      <div class="fr-content">
        <div class="sticky-p" style="top: 30px; margin-top: 30px">
          <h2>{{ trans('MISSIONS_EXAMPLES') }}</h2>
        </div>
        <div class="columns sticky-p columns1">
          <div class="column is-6 mission" :style="{'opacity': mission1Opacity}" :class="{'mission-fade': missionFade}">
            <div id="mission1">
              <b><i class="fas fa-vial"></i>&nbsp; Troubleshooting</b>
              <ul>
                <li>{{ trans('INCIDENTS') }}</li>
                <li>{{ trans('PERF_ISSUES') }}</li>
              </ul>
            </div>
          </div>
          <div class="column is-6 mission" :style="{'opacity': mission2Opacity}" :class="{'mission-fade': missionFade}">
            <div id="mission2">
              <b><i class="far fa-file-code"></i>&nbsp; Proof of concepts</b>
              <ul>
                <li>{{ trans('POC_K8S') }}</li>
                <li>{{ trans('POC_GITOPS') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div ref="startOfMissions"></div>
        <div class="columns is-centered sticky-p columns2">
          <div class="column is-6 mission" :style="{'opacity': mission3Opacity}" :class="{'mission-fade': missionFade}">
            <div id="mission3">
              <b><i class="fas fa-cogs"></i>&nbsp; CI/CD</b>
              <ul>
                <li>{{ trans('CI_CONTAINERS') }}</li>
                <li>{{ trans('CI_PIPES') }}</li>
                <li>{{ trans('CI_K8S') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div ref="endOfMissions"></div>
        <div class="below-part"></div>
      </div>
    </div>

    <div id="skills" class="block" :class="{'hidden-block': hiddenSkills}">
      <div class="fr-content">
        <div class="sticky-p" style="top: 0px; padding-top: 30px">
          <h2>Technologies</h2>
        </div>
        <div class="columns is-mobile">
          <div class="column is-6">
            <div class="sticky-p" id="skills-logo-sticky1">
              <img src="img/kubernetes.png">
            </div>

            <div class="sticky-p tags" id="skills-sticky2">
              <div class="tags-list">
                <p class="p1">Bamboo</p>
                <p class="p2">{{ trans('REGISTRY_MANAGEMENT') }}</p>
                <p class="p3">{{ trans('DOCKER_BUILDS') }}</p>
                <p class="p4">GitOps (ArgoCD)</p>
                <p class="p5">Helm</p>
                <p class="p6">SecOps</p>
                <p class="p7">Gitlab CI</p>
              </div>
            </div>
            <div class="sticky-p" id="skills-logo-sticky3">
              <img src="img/django.png">
            </div>
          </div>
          <div class="column is-6">
            <div class="sticky-p tags" id="skills-sticky1">
              <div class="tags-list">
                <p class="p1">{{ trans('DEPLOYMENT') }}</p>
                <p class="p2">Cloud (EKS, Scaleway)</p>
                <p class="p4">{{ trans('SECOPS') }}</p>
                <p class="p5">{{ trans('RESILIENCE') }}</p>
                <p class="p3">{{ trans('OBSERVABILITY') }}</p>
                <p class="p6">{{ trans('SECRETS') }}</p>
                <p class="p7">On prem (Openshift)</p>
              </div>
            </div>
            <div class="sticky-p" id="skills-logo-sticky2">
              <img src="img/ci-cd.png">
            </div>
            <div class="sticky-p tags" id="skills-sticky3">
              <div class="tags-list">
                <p class="p1">{{ trans('DJANGO_VIEW') }}</p>
                <p class="p2">{{ trans('ASYNC') }}</p>
                <p class="p3">API REST</p>
                <p class="p4">ORM Postgres+Mongo</p>
                <p class="p5">{{ trans('MIGRATE_OPTIM') }}</p>
                <p class="p6">Middlewares, cache</p>
                <p class="p7">Celery</p>
              </div>
            </div>
            <div ref="endOfSkills"></div>

            <div class="below-part"></div>

          </div>
        </div>
      </div>
    </div>

    <div id="commitment" class="block" :class="{'hidden-block': hiddenCommitment}">
      <div class="fr-content">
        <div class="sticky-p" style="top: 30px; margin-top: 30px">
          <h2>{{ trans('MY_COMMITMENTS') }}</h2>
        </div>

        <div class="pro sticky-p" style="top: 120px; margin-top: 120px">
          <div class="pro-title">
            <i class="fas fa-bolt"></i>
            <b>{{ trans('EFFICIENCY') }}</b>
          </div>
          <p>
            {{ trans('EFFICIENCY_DETAILS') }}
          </p>
        </div>

        <div class="pro sticky-p" style="top: 240px; margin-top: 320px">
          <div class="pro-title">
            <i class="fas fa-vector-square"></i>
            <b>{{ trans('RIGOROUS') }}</b>
          </div>
          <p>
            {{ trans('RIGOROUS_DETAILS') }}
          </p>
        </div>

        <div class="pro sticky-p" style="top: 360px; margin-top: 520px">
          <div class="pro-title">
            <i class="fas fa-bullhorn"></i>
            <b>{{ trans('COMMUNICATION') }}</b>
          </div>
          <p>
            {{ trans('COMMUNICATION_DETAILS') }}
          </p>
        </div>

        <div class="pro sticky-p" style="top: 480px; margin-top: 620px">
          <div class="pro-title">
            <i class="fas fa-coffee"></i>
            <b>{{ trans('GOOD_MOOD') }}</b>
          </div>
          <p>
            {{ trans('GOOD_MOOD_DETAILS') }}
          </p>
        </div>

        <div ref="endOfCommitment"></div>
        <div class="below-part"></div>
      </div>
    </div>
<!--
    <div id="methodology">
      <div class="fr-content">
        <div class="sticky-p no-sticky-mobile" style="top: 30px">
          <h2>{{ trans('METHODOLOGY') }}</h2>
        </div>
        <div ref="startOfMethodo"></div>

        <div class="tile is-ancestor sticky-p no-sticky-mobile" style="top: 90px; margin-top: 120px">
          <div class="tile is-vertical is-8">
            <div class="tile is-parent">
              <article class="tile is-child notification color1" :style="{'opacity': methodo1Opacity}" :class="{'methodo-fade': methodoFade}">
                <p class="title">{{ trans('METHODO_1') }}</p>
                <p class="subtitle"><i class="fas fa-clock"></i>&nbsp;1h - remote</p>
                <div class="content" v-html="trans('METHODO_1_DETAILS')">
                </div>
              </article>
            </div>
            <div class="tile">
              <div class="tile is-parent">
                <article class="tile is-child notification color2" :style="{'opacity': methodo2Opacity}" :class="{'methodo-fade': methodoFade}">
                  <p class="title">{{ trans('METHODO_2') }}</p>
                  <p class="subtitle"><i class="fas fa-clock"></i>&nbsp;½ {{ trans('DAY') }}</p>
                  <div class="content" v-html="trans('METHODO_2_DETAILS')"/>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child notification color3" :style="{'opacity': methodo3Opacity}" :class="{'methodo-fade': methodoFade}">
                  <p class="title">{{ trans('METHODO_3') }}</p>
                  <div class="content" v-html="trans('METHODO_3_DETAILS')"/>
                </article>
                <article class="tile is-child notification color5" id="methodo5" :style="{'opacity': methodo5Opacity}" :class="{'methodo-fade': methodoFade}">
                  <p class="title">{{ trans('METHODO_5') }}</p>
                  <div class="content" v-html="trans('METHODO_5_DETAILS')"/>
                </article>
              </div>
            </div>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child notification color4" :style="{'opacity': methodo4Opacity}" :class="{'methodo-fade': methodoFade}">
              <p class="title">{{ trans('METHODO_4') }}</p>
              <div class="content" v-html="trans('METHODO_4_DETAILS')"/>
            </article>
          </div>
        </div>

        <div class="below-part"></div>
        <div ref="endOfMethodo"></div>

      </div>
    </div> -->

    <div id="map">
        <div class="sticky-p" style="top: 30px">
          <h2>{{ trans('WORK_AREA') }}</h2>
        </div>
        <div ref="startOfMap"></div>
        <div class="sticky-p" style="top: 100px">
          <div id="map-wrapper" :class="{'map-fade': mapFade}">
            <div class="overlay" id="map-legend">
              {{ trans('TRANSPORTS') }}<br/>
              <div class="legend">
                <div class="color"></div>
                <span>{{ trans('FREQ_WEEKLY') }}</span>
              </div>
              <div class="legend">
                <div class="color"></div>
                <span>{{ trans('FREQ_BIWEEKLY') }}</span>
              </div>
              <!-- <div class="legend">
                <div class="color"></div>
                <span>{{ trans('FREQ_MONTHLY') }}</span>
              </div> -->
              <div class="legend">
                <div class="color"></div>
                <span>{{ trans('FREQ_REMOTE') }}</span>
              </div>
            </div>
            <!-- <img class="overlay" id="overlay-close" src="img/close.png"> -->
            <img class="overlay" id="overlay-layer1" src="img/nantes.png" :style="{'opacity': mapLayer1Opacity}">
            <img class="overlay" id="overlay-layer2" src="img/angers.png" :style="{'opacity': mapLayer2Opacity}">
            <img src="img/france.png">
          </div>
        </div>
        <div class="below-part"></div>
        <div ref="endOfMap"></div>
    </div>

  </div>
</template>

<script>
import I18nMixin from '@/mixins/i18nmixin'

export default {
  name: 'Home',
  mixins: [I18nMixin],
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  data () {
    return {
      languageOptions: [
        { text: 'Français', value: 'FR' },
        { text: 'English', value: 'EN' }
      ],
      translations: {
        SEE_CV: {
          FR: 'Voir mon CV',
          EN: 'See my CV'
        },
        AGE: {
          FR: '38 ans',
          EN: '38 years old'
        },
        WORK_MODALITIES: {
          FR: 'Missions à temps partiel (max. 2j/semaine)',
          EN: 'Part-time missions (max. 2d a week)'
        },
        KNOWLEDGE: {
          FR: 'Un savoir-faire transverse : Fullstack DevOps + Dev',
          EN: 'A knowledge from Dev to Ops, through DevOps'
        },
        MISSIONS_EXAMPLES: {
          FR: 'Exemples de missions',
          EN: 'A few projects examples...'
        },
        INCIDENTS: {
          FR: 'Investigation d\'incidents (erreurs 500, 503, timeouts, ....)',
          EN: 'Prod incidents (500, 503, timeouts, ...)'
        },
        PERF_ISSUES: {
          FR: 'Comprendre une dégradation de performances',
          EN: 'Undestanding slow performances'
        },
        POC_K8S: {
          FR: 'POC Kubernetes (cloud ou on prem\')',
          EN: 'Kubernetes POC (cloud / on prem\')'
        },
        POC_GITOPS: {
          FR: 'POC GitOps',
          EN: 'GitOps POC'
        },
        DEV_FIXES: {
          FR: 'Correction bugs/performance',
          EN: 'Bug fixing / Performance improvements'
        },
        DEV_FEATURES: {
          FR: 'Nouvelles features',
          EN: 'New features'
        },
        DEV_UPGRADES: {
          FR: 'Upgrades (Vue 3, Django 4, ...)',
          EN: 'Upgrades (Vue 3, Django 4, ...)'
        },
        CI_CONTAINERS: {
          FR: 'Containerisation d\'applications',
          EN: 'App containerization'
        },
        CI_PIPES: {
          FR: 'Mise en place de pipeline',
          EN: 'Implementing CI/CD pipelines'
        },
        DOCKER_BUILDS: {
          FR: 'Builds Docker',
          EN: 'Docker builds'
        },
        REGISTRY_MANAGEMENT: {
          FR: 'Gestion des registries (Conteneurs, dépendances)',
          EN: 'Registry management (Containers, dependencies)'
        },
        CI_K8S: {
          FR: 'Spécificités Kubernetes',
          EN: 'Kubernetes specificities'
        },
        DJANGO_VIEW: {
          FR: 'Vues classiques',
          EN: 'Standard views'
        },
        ASYNC: {
          FR: 'Asynchrone',
          EN: 'Asynchronous'
        },
        MIGRATE_OPTIM: {
          FR: 'Migrations, optimisations',
          EN: 'Migrations, optimizations'
        },
        DEPLOYMENT: {
          FR: 'Déploiement',
          EN: 'Deployment'
        },
        SECRETS: {
          FR: 'Gestion des secrets',
          EN: 'Secrets management'
        },
        OBSERVABILITY: {
          FR: 'Observabilité',
          EN: 'Observability'
        },
        SECOPS: {
          FR: 'Containers et sécurité',
          EN: 'Container security'
        },
        RESILIENCE: {
          FR: 'Résilience',
          EN: 'Resilience'
        },
        MY_COMMITMENTS: {
          FR: 'Mes engagements',
          EN: 'My commitments'
        },
        EFFICIENCY: {
          FR: 'Efficacité',
          EN: 'Efficiency'
        },
        EFFICIENCY_DETAILS: {
          FR: 'Très productif, j\'ai pour habitude de tenir les délais sur lesquels je m\'engage.',
          EN: 'Highly productive. When I commit myself to a deadline, I always meet the expectations.'
        },
        RIGOROUS: {
          FR: 'Rigueur',
          EN: 'Meticulousness'
        },
        RIGOROUS_DETAILS: {
          FR: 'Même si les contraintes de temps sont fortes, mon code est structuré, lisible, documenté et testé.',
          EN: 'Even if the deadline is short, I\'ll do my best to write clean code, well structured, documented and tested'
        },
        COMMUNICATION: {
          FR: 'Communication',
          EN: 'Communication'
        },
        COMMUNICATION_DETAILS: {
          FR: 'J\'envoie régulièrement un rapport régulier sur ma progression.',
          EN: 'I regularly send a report on progress'
        },
        GOOD_MOOD: {
          FR: 'Bonne humeur et convivialité',
          EN: 'Nice working atmosphere'
        },
        GOOD_MOOD_DETAILS: {
          FR: 'Parce que mon souhait est avant tout de partager avec vous un défi !',
          EN: 'Because we share the same challenge together!'
        },
        METHODOLOGY: {
          FR: 'La méthodologie',
          EN: 'Methodology'
        },
        METHODO_1: {
          FR: '1. Présentation rapide du projet',
          EN: '1. Quick project description'
        },
        METHODO_1_DETAILS: {
          FR: 'Lors de cette première entrevue, vous décrivez les enjeux du projet dans ses grandes lignes : objectifs, technologies, deadline.<br/><br/>A l\'issue de l\'entretien, je vous donne mon go/no-go',
          EN: 'During this first meeting, you describe the main objectives, technologies and the expected deadline.<br/><br/>From these elements, I am able to give you a go-nogo.'
        },
        DAY: {
          FR: 'journée',
          EN: 'day'
        },
        METHODO_2: {
          FR: '2. Présentation détaillée du projet',
          EN: '2. Detailed project description'
        },
        METHODO_2_DETAILS: {
          FR: 'Dans le cadre d\'un échange, nous construisons ensemble les différentes étapes et les détails de notre coopération.<br/><br/>Je réalise par la suite un devis (par étape définie)',
          EN: 'We define together the project steps and how we would cooperate<br/><br/>After this meeting, I provide you a quote (one amount per step)'
        },
        METHODO_3: {
          FR: '3. Onboarding',
          EN: '3. Onboarding'
        },
        METHODO_3_DETAILS: {
          FR: 'Un à 2 jours sur place, au sein de vos équipes, pour une immersion initiale et la prise en main du projet.',
          EN: 'One or two days on-site, working alongside your team, to kick-off the project and setup everything I will need.'
        },
        METHODO_4: {
          FR: '4. Review des milestones',
          EN: '4. Milestones reviews'
        },
        METHODO_4_DETAILS: {
          FR: 'A chaque milestone défini (max. 1 mois), point d\'étape et éventuellement affinage du milestone.',
          EN: 'At each milestone (at least once per month), we do an intermediate progress report/meeting with a potential update of the next step'
        },
        METHODO_5: {
          FR: '5. Fin de projet',
          EN: '5. Project end'
        },
        METHODO_5_DETAILS: {
          FR: 'Bilan sur le déroulé de l\'opération.',
          EN: 'Mission debriefing - potential improvements'
        },
        WORK_AREA: {
          FR: 'Mes zones d\'intervention',
          EN: 'Intervention areas'
        },
        TRANSPORTS: {
          FR: 'Déplacements possibles :',
          EN: 'Business trips :'
        },
        FREQ_WEEKLY: {
          FR: '1j / semaine',
          EN: '1d per week'
        },
        FREQ_BIWEEKLY: {
          FR: '1j / 2 semaines',
          EN: '1d every 2 weeks'
        },
        // FREQ_MONTHLY: {
        //   FR: '1j / mois',
        //   EN: '1d per month'
        // },
        FREQ_REMOTE: {
          FR: '100% télétravail',
          EN: 'Full remote'
        }
      },
      hiddenSkills: false,
      hiddenCommitment: false,
      mission1Opacity: 0,
      mission2Opacity: 0,
      mission3Opacity: 0,
      methodo1Opacity: 1,
      methodo2Opacity: 0,
      methodo3Opacity: 0,
      methodo4Opacity: 0,
      methodo5Opacity: 0,
      scrollProgress: 0,
      mapLayer1Opacity: 0,
      mapLayer2Opacity: 0,
      missionFade: false,
      methodoFade: false,
      mapFade: false
    }
  },
  methods: {
    capitalize ([firstLetter, ...restOfWord]) {
      return firstLetter.toUpperCase() + restOfWord.join('')
    },
    onScroll () {
      this.scrollProgress = Math.round(100 * window.scrollY / (document.body.clientHeight - window.innerHeight))
      var skillsPosition = this.$refs.endOfSkills.getBoundingClientRect()
      this.hiddenSkills = skillsPosition.top <= 0

      var commitmentPosition = this.$refs.endOfCommitment.getBoundingClientRect()
      this.hiddenCommitment = commitmentPosition.top <= 0

      var missionsPosition = this.$refs.startOfMissions.getBoundingClientRect()
      var endMissionsPosition = this.$refs.endOfMissions.getBoundingClientRect()
      if (missionsPosition.top > window.innerHeight || endMissionsPosition.top < -300) {
        this.mission1Opacity = 0
        this.mission2Opacity = 0
        this.mission3Opacity = 0
        this.missionFade = true
      } else {
        this.missionFade = false
        this.mission1Opacity = Math.max(Math.min(1 - 3 * ((missionsPosition.top - 400) / window.innerHeight), 1), 0)
        this.mission2Opacity = Math.max(Math.min(1 - 3 * ((missionsPosition.top - 300) / window.innerHeight), 1), 0)
        this.mission3Opacity = Math.max(Math.min(1 - 3 * ((missionsPosition.top) / window.innerHeight), 1), 0)
      }

      // var methodoPosition = this.$refs.startOfMethodo.getBoundingClientRect()
      // var endMethodoPosition = this.$refs.endOfMethodo.getBoundingClientRect()
      // if (methodoPosition.top > window.innerHeight || endMethodoPosition.top < -300) {
      //   this.methodo2Opacity = 0
      //   this.methodo3Opacity = 0
      //   this.methodo4Opacity = 0
      //   this.methodo5Opacity = 0
      //   this.methodoFade = true
      // } else {
      //   this.methodoFade = false
      //   this.methodo2Opacity = Math.max(Math.min((-methodoPosition.top / (window.innerHeight / 5)), 1), 0)
      //   this.methodo3Opacity = Math.max(Math.min(((-methodoPosition.top - 1 * window.innerHeight / 5) / (window.innerHeight / 5)), 1), 0)
      //   this.methodo4Opacity = Math.max(Math.min(((-methodoPosition.top - 2 * window.innerHeight / 5) / (window.innerHeight / 5)), 1), 0)
      //   this.methodo5Opacity = Math.max(Math.min(((-methodoPosition.top - 3 * window.innerHeight / 5) / (window.innerHeight / 5)), 1), 0)
      // }

      var mapPosition = this.$refs.startOfMap.getBoundingClientRect()
      var endMapPosition = this.$refs.endOfMap.getBoundingClientRect()
      if (mapPosition.top > window.innerHeight || endMapPosition.top < -500) {
        this.mapLayer1Opacity = 0
        this.mapLayer2Opacity = 0
        this.mapFade = true
      } else {
        this.mapFade = false
        this.mapLayer1Opacity = Math.max(Math.min(((-mapPosition.top - 1 * window.innerHeight / 5) / (window.innerHeight / 5)), 1), 0)
        this.mapLayer2Opacity = Math.max(Math.min(((-mapPosition.top - 2 * window.innerHeight / 5) / (window.innerHeight / 5)), 1), 0)
      }
    }
  }
}
</script>

<style lang="sass">

  #language-select
    top: 5px
    left: 5px
    z-index: 15

  #language-select
    position: absolute
    width: 100px
    opacity: 0.8

  #cv-button
    position: fixed
    top: 10px
    right: 10px
    @media (max-width: 768px)
      font-size: 12px
      opacity: 0.7
      top: 0px
      right: 0px
      border: 0px
      &:focus, &:active
        border: 0px

  .hidden-block
    .column,.pro
      opacity: 0

  .columns
    margin: 0px !important

  .sticky-p.tags
    justify-content: left

  .p1
    font-size: 16px
    white-space: nowrap
  .p2
    font-size: 14px
    white-space: nowrap
  .p3
    font-size: 24px
    font-weight: 500
    white-space: nowrap
  .p4
    font-size: 26px
    white-space: nowrap
  .p5
    font-size: 18px
    font-weight: 500
    white-space: nowrap
  .p6
    font-size: 12px
    font-weight: bold
    white-space: nowrap
  .p7
    font-size: 20px
    white-space: nowrap

  h2
    font-size: 26px
    font-weight: 500
    padding-bottom: 15px
    letter-spacing: 4px

  #missions
    background: url(/img/lines-diagonal.png) #BBEED6
    background-repeat: repeat
    background-size: 100% auto

    .mission
      font-size: 22px
      padding: 70px 20px 0px 20px
      @media (max-width: 768px)
        padding: 20px 20px 0px 20px
        font-size: 16px
      li
        text-align: left
      opacity: 0
      transition-duration: 0s

      &.mission-fade
        transition-duration: 1s

    .columns1
      top: 80px
      margin-top: 0px
      @media (max-width: 768px)
        flex-wrap: wrap
        top: 120px

    .columns2
      top: 320px
      margin-top: 0px
      @media (max-width: 768px)
        top: 430px

  .fr-content
    display: block
    max-width: 800px
    margin: auto
    padding: 0px 20px

  .fr-subtitle
    h2
      font-weight: 300
      font-size: 24px
    padding: 40px 0px 15px 0px

  .block
    color: rgb(34, 34, 34)

  .sticky-p
    z-index: 2
    position: -webkit-sticky
    position: sticky
    display: flex
    align-items: center
    justify-content: center
    transition: top 2s ease 1s
    transition: bottom 2s ease 0s

  .no-sticky-mobile
    @media (max-width: 768px)
      position: relative
      margin-top: 0px !important
      top: 0px !important

  .column
    opacity: 1
    transition-property: opacity
    transition-duration: 1s

  .wrapper
    background: rgb(131,58,180)

  .above-part
    height: 200vh

  .below-part
    height: 120vh

  body
    margin: 0
    font-family: 'Inter', sans-serif
    font-family: 'Roboto', sans-serif
    display: flex
    flex-direction: column
    align-content: center
    width: 100%

  main
    top: 64px
    padding: 0 16px
    min-height: 100vh

  #title-part
    padding-top: 100px
    padding-bottom: 140px
    text-align: center
    letter-spacing: 4px
    font-weight: 400
    font-size: 30px
    background-color: rgb(212, 201, 201)
    h1
      font-weight: 500
      margin: 0
      padding: 50px 0px
      @media (max-width: 768px)
        font-size: 44px

    .infos
      font-size: 20px

  #skills
    position: relative
    margin-bottom: -32px
    &::before
      width: 100%
      position: absolute
      content: ''
      bottom: 0
      top: 0
      left: 0
      right: 0
      opacity: 0.03
      z-index: -1
      background: url('/img/curve.png')
      background-size: 100% auto
      background-position: center
      background-repeat: repeat
    .columns
      max-width: 100%
    img
      height: 100px

    @media (min-width: 768px)
      .tags-list
        color: #999

      #skills-sticky1
        .tags-list
          color: #042b19
          opacity: 0.6

      #skills-sticky2
        .tags-list
          color: #2cbf84

      #skills-sticky3
        .tags-list
          color: #3f6de9

    @media (max-width: 768px)
      img
        width: 120px
        height: auto
      .tags-list
        // max-width: 100px
        p
          display: block
          font-size: 14px !important
          white-space: normal !important
          margin: 2px 0px
          text-align: center
      .column.is-6
        flex-basis: 45%  // Weird bug

    @media (min-width: 769px)
      p
        position: absolute
        &.p1
          margin-left: 150px
        &.p2
          margin-top: 100px
          margin-left: 230px
        &.p3
          margin-top: 125px
          margin-left: 150px
        &.p4
          margin-top: 57px
        &.p5
          margin-top: 850px
          margin-left: 115px
        &.p6
          margin-top: 112px
          margin-left: 30px
        &.p7
          margin-top: 35px
          margin-left: 275px

  #conditions
    background-color: #eeaa77

  #skills-logo-sticky1
    top: 115px
    margin-top: 200px

  #skills-sticky2
    top: 265px
    margin-top: 600px

  #skills-logo-sticky3
    top: 502px
    margin-top: 800px

  #skills-sticky1
    top: 65px
    margin-top: 200px

  #skills-logo-sticky2
    top: 302px
    margin-top: 600px

  #skills-sticky3
    top: 465px
    margin-top: 800px

  @media (max-width: 768px)
    #skills-sticky2, #skills-logo-sticky2
      margin-top: 300px

    #skills-sticky3, #skills-logo-sticky3
      margin-top: 600px

    #skills-sticky1
      top: 90px

  #commitment
    background: url(/img/stray.png) #d4c9c9
    background-size: 1000px 100%
    background-repeat: no-repeat
    background-position: top center
    @media (max-width: 768px)
      background-size: 700px 100%

    .pro
      flex-direction: column
      .pro-title
        font-size: 24px
        b
          font-weight: normal
        i
          padding-right: 10px
      p
        font-size: 16px
      transition-property: opacity
      transition-duration: 1s

  #methodology
    position: relative
    .title
      font-size: 24px
    .content
      text-align: left
      font-size: 14px
      padding: 10px
    .tile
      &.is-parent,&.is-child
        padding: 10px

    @media (max-width: 768px)
      #methodo5
        display: none
      .below-part
        display: none

    &::before
      width: 100%
      position: absolute
      content: ''
      bottom: 0
      top: 0
      left: 0
      right: 0
      opacity: 0.3
      z-index: -1
      background: url('/img/whiteboard.jpeg')
      background-size: 100% auto
      background-position: top center
      background-repeat: no-repeat

    .methodo-fade
      transition-duration: 1s

    .color1
      background-color: #BBEED6
    .color2
      background-color: #995D81
      color: white
    .color3
      background-color: #EB8258
      color: white
    .color4
      background-color: #6689A1
      color: white
    .color5
      background-color: #7699D4
      color: white

  #map
    background: url(/img/lines-diagonal.png) #BBEED6
    background-repeat: repeat
    background-size: 100% auto

    #map-wrapper
      position: relative
      img
        width: 60%
        @media (max-width: 768px)
          width: 80%
        z-index: 5
      .overlay
        position: absolute
        z-index: 10

    .map-fade
      transition-duration: 1s

    #map-legend
      top: 10px
      right: 10px
      @media (max-width: 768px)
        top: 110%

      background-color: white
      padding: 10px
      text-align: left
      .legend
        div, span
          display: inline-block

        .color
          width: 16px
          height: 16px
          margin-top: 5px
          margin-right: 5px
        &:nth-of-type(1) .color
          background-color: #995d81
        &:nth-of-type(2) .color
            background-color: #eb8258
        &:nth-of-type(3) .color
          border: solid 1px black
          width: 14px
          height: 14px

</style>
