<script>
/*
** Base mixin  translation
*/
export default {
  computed: {
    lang () {
      return this.$store.state.lang
    }
  },
  methods: {
    trans (key) {
      return this.translations[key][this.lang]
    },
    transDict (textDict) {
      return textDict[this.lang]
    },
    changeLang (evt) {
      this.$store.commit('setLang', evt.target.value)
    }
  }
}

</script>
