<template>
  <div>
      <router-view/>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass">
#app
  text-align: center
  color: #2c3e50
  height: 100%

#app, button
  font-family: "Open Sans", Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

button.button
  white-space: normal
  max-width: 90%
  height: auto

// Trying to disable a bulma CSS affecting columns background
.columns:not(:last-child)
  margin-bottom: 0px !important

</style>
